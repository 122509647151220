.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
}
  
.modal-main {
    position:fixed;
    background: white;
    width: 500px;
    height: 70%;
    top: 45%;
    left:50%;
    transform: translate(-50%,-50%);
    box-shadow: 0 0 20px rgba(0,0,0,0.3);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    animation-name: enter-in;
    animation-duration: 0.3s;
    animation-timing-function: linear;
    overflow-y: scroll;
    overflow-x: hidden;
}

@keyframes enter-in {
    0%   {top:80%;}
    25%  {top:70%;}
    50%  {top:60%;}
    75%  {top:50%;}
    100% {top:40%;}
}

.display-block {
    display: block;
}
  
.display-none {
    display: none;
}

.close {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 25px;
    height: 25px;
    opacity: 0.3;
}
.close:hover {
    opacity: 1;
}
.close:before, .close:after {
    position: absolute;
    left: 12px;
    content: ' ';
    height: 25px;
    width: 2px;
    background-color: #333;
}

.close:before {
    transform: rotate(45deg);
}
.close:after {
    transform: rotate(-45deg);
}

.title {
    margin-top: 10px;
    align-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 600;
}

.stats {
    justify-content: center;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.stat {
    width: 65px;
    margin-left: 5px;
    margin-right: 5px;
}

.number {
    font-size: 40px;
}

p {
    font-size: 15px;
    line-height: 15px;
    justify-content: top;
}

.graph {
    background-image: url("../../../images/stuff.jpg");
    background-repeat: no-repeat;
    background-size: 400px 400px;
    width: 400px;
    height: 400px;
}

.countDown {
    justify-content: center;
    align-items: center;
    text-align: center;
}

.divider {
    border-left: 6px solid black;
    height: 500px;
    width: 0.2px;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    justify-content: center;
    background-color: black;
}